



































import { Component, Vue, Prop } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'

@Component({
  components: {
    Button,
  },
})
export default class Card extends Vue {
  @Prop({ default: '' }) private title!: string
  @Prop({ default: '' }) private description!: string
  @Prop({ default: false }) private useSingleButton!: boolean
  @Prop({ default: '' }) private primaryText!: string
  @Prop({ default: '' }) private secondText!: string
}
