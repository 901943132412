






































































import { Component, Prop, Vue } from 'vue-property-decorator'
import AttachSmallIcon from '@/app/ui/assets/ics_o_attach.vue'

@Component({
  components: {
    AttachSmallIcon,
  },
})
export default class FileAttachment extends Vue {
  @Prop({ type: [File, String], required: false }) private file!: File | string
  @Prop({ type: String, default: '' }) private filename!: string
  @Prop({ default: 'file' }) private type!: 'file' | 'download' | 'url-file'
  @Prop({ type: String, default: '' }) private url!: string

  get fileNameFromUrl(): string {
    const urlArr = (<string>this.file).split('/')
    return urlArr[urlArr.length - 1]
  }
}
