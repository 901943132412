

















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import CloudUploadIcon from '@/app/ui/assets/cloud_upload_icon.vue'
import AttachIcon from '@/app/ui/assets/attach_icon.vue'
import { Utils } from '@/app/infrastructures/misc'
import { EnumStatusUpload } from '@/app/infrastructures/misc/Constants/manualAdjustSaldo'
import FileAttachment from './FileAttachment/index.vue'

@Component({
  components: {
    CloudUploadIcon,
    AttachIcon,
    FileAttachment,
  },
})
export default class FileUpload extends Vue {
  @Prop({ type: String, default: '' }) private fieldLabel?: string
  @Prop({ type: Boolean, default: false }) private required?: boolean
  @Prop({ type: String }) private accept?: string
  @Prop({ type: String }) private format?: string
  @Prop({ type: String, default: 'Upload Image' }) private text!: string
  @Prop({ type: String, default: '' }) private uploadDescription!: string
  @Prop({ type: String, default: '' }) private filename!: string
  @Prop({ type: String, default: '' }) private status!: EnumStatusUpload
  @Prop({ type: Boolean, default: false }) private isError!: boolean
  @Prop({ type: String, default: 'Error' }) private errorMessage!: string
  @Prop({ type: Number }) private maxFileSize!: number // in MB
  @Prop({ type: Boolean, default: false }) private forceStart!: boolean
  @Prop({ type: String, default: '' }) private description!: string
  @Prop({ type: Boolean, default: false }) private multiple!: boolean
  @Prop({ type: Array, default: () => [], required: false })
  files!: File[] | string[]
  @Prop({ type: Number, default: 99}) private maxFiles!: number

  dragOver = false
  error = false
  EnumStatusUpload = EnumStatusUpload

  private onInputFileChange(files: File) {
    this.error = false

    try {
      if (files) {
        const splitFileName = files.name.split('.')
        const formatFiles = splitFileName[splitFileName.length - 1]

        // check file format
        if (
          this.format &&
          !this.format.split(',').includes(`.${formatFiles}`)
        ) {
          this.error = true
          throw new Error(`File Type must be in ${this.format}`)
        }

        // check filesize
        if (this.maxFileSize) {
          if (Math.round(files.size / 1024) > this.maxFileSize * 1024) {
            this.error = true
            throw new Error(`File size exceeds ${this.maxFileSize}MB`)
          }
        }

        this.$emit('on-input', files)
      }
    } catch (error) {
      const err = <Error>error
      // Show error alert
      this.$notify({
        title: 'Input File Failed',
        text: err.message,
        type: 'error',
        duration: 5000,
      })
    }
  }

  public onInputMultipleFileChange(files: FileList): void {
    const tempFiles = <File[]>this.files
    if (files.length) {
      let valid = true
      let i = 0

      try {
        while (valid) {
          if (files) {
            const splitFileName = files[i].name.split('.')
            const formatFiles = splitFileName[splitFileName.length - 1]

            // check max files
            if (this.files.length === this.maxFiles) {
              this.error = true
              throw new Error(`Maximum file exceeds ${this.maxFiles} files`)
            }

            // check file format
            if (
              this.format &&
              !this.format
                .split(',')
                .map(format => format.trim())
                .includes(`.${formatFiles}`)
            ) {
              this.error = true
              throw new Error(`File Type must be in ${this.format}`)
            }

            // check filesize
            if (this.maxFileSize) {
              const fileSize = Math.round(files[i].size / 1024)
              if (fileSize > this.maxFileSize * 1024) {
                this.error = true
                valid = false
                throw new Error(
                  `File size exceeds ${
                    this.maxFileSize < 1
                      ? Math.round(this.maxFileSize * 1000)
                      : this.maxFileSize
                  }${this.maxFileSize < 1 ? 'KB' : 'MB'}`
                )
              }
            }
            tempFiles.push(files[i])
            i++

            if (i === files.length) {
              valid = false
              this.$emit('on-input', tempFiles)
            }
          }
        }
      } catch (error) {
        const err = <Error>error
        // Show error alert
        this.$notify({
          title: 'Input File Failed',
          text: err.message,
          type: 'error',
          duration: 5000,
        })
      }
    }
  }

  private drop({ dataTransfer }: { dataTransfer: DataTransfer }) {
    this.dragOver = false

    if (this.multiple) {
      this.onInputMultipleFileChange(dataTransfer?.files)
    } else {
      this.onInputFileChange(dataTransfer?.files[0])
    }
  }

  private dragover() {
    this.dragOver = true
  }

  private dragleave() {
    this.dragOver = false
  }

  private setLoadingProgress = Utils.debounce(
    (size: number, transitionTimeInSecond: number) => {
      const progress = document.getElementById('loading-bar')
      const bar = document.getElementById('bar')
      if (progress && bar) {
        progress.style.setProperty('--size', String(size))
        bar.style.transition = `margin-left ${transitionTimeInSecond}s`
      }
    },
    0
  )

  public deleteFile(index: number): void {
    const tempFiles = this.files
    tempFiles.splice(index, 1)
    this.$emit('on-input', tempFiles)
  }

  public changeFile(file: File, index: number): void {
    const tempFiles = Array.from(<File[]>this.files)
    tempFiles[index] = file
    this.$emit('on-input', tempFiles)
  }

  @Watch('status')
  onSetLoadingState(data: string): void {
    if (data === EnumStatusUpload.PROCESSING) {
      this.setLoadingProgress(0.5, 30)
    }

    if (
      data === EnumStatusUpload.COMPLETE ||
      data === EnumStatusUpload.FAILED
    ) {
      this.setLoadingProgress(1, 0.2)
    }
  }

  @Watch('isError')
  onCheckError(state: boolean): void {
    this.error = state
  }
}
