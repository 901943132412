import {container} from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import Axios, {CancelTokenSource} from 'axios'
import {
  VoucherSetLimitList,
  VoucherSetLimit,
  UploadBulkyVoucherSetLimitSpecificCustomer
} from '@/domain/entities/VoucherConfigSetLimit'
import {Pagination} from '@/domain/entities/Pagination'
import {Utils} from "@/app/infrastructures/misc"
import {VoucherConfigSetLimitPresenter} from '@/app/ui/presenters/VoucherConfigSetLimitPresenter'
import {EnumStatusUpload} from '@/app/infrastructures/misc/Constants/upload'
import {UploadRequest} from '@/data/payload/api/UploadRequest'
import {
  VoucherConfigSetLimitPayloadInterface,
  VoucherConfigSetLimitRequest
} from '@/data/payload/api/VoucherConfigSetLimitRequest'

export interface VoucherConfigSetLimitInterface {
  page?: number | string
  perPage?: number | string
  sort: string
  filterBy?: string
  keyword?: string
  status?: string
}

export interface VoucherConfigSetLimitState {
  isLoading: boolean
  isSave: boolean
  successDelete: boolean
  paginationData: Pagination
  listData: VoucherSetLimit[]
  detailData: VoucherSetLimit | null
  statusUploadBulky: EnumStatusUpload
  errUploadBulky: null | string
  forceStart: boolean
  cancelToken: CancelTokenSource | undefined
  dataRetry: number
  responseUploadBulky: UploadBulkyVoucherSetLimitSpecificCustomer | null
  fileUploaded: File | null
}

@Module({namespaced: true, store, name: 'voucher-config-set-limit', dynamic: true})
class VoucherConfigSetLimitController extends VuexModule implements VoucherConfigSetLimitState {
  private presenter: VoucherConfigSetLimitPresenter = container.resolve(
    VoucherConfigSetLimitPresenter
  )

  isLoading = false
  isSave = false
  successDelete = false
  paginationData: Pagination = new Pagination()
  listData: VoucherSetLimit[] = []
  detailData: VoucherSetLimit | null = null
  statusUploadBulky = EnumStatusUpload.START
  errUploadBulky: null | string = null
  forceStart = false
  cancelToken: CancelTokenSource | undefined = undefined
  dataRetry = NaN
  responseUploadBulky: UploadBulkyVoucherSetLimitSpecificCustomer | null = null
  fileUploaded: File | null = null

  @Action({rawError: true})
  public getAll(params: VoucherConfigSetLimitInterface) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setListData(res)
      })
      .catch(err => {
        Vue.notify({
          title: 'Fetch List Voucher SKU Limit Failed',
          text:
            err?.status >= 400 && err?.status < 500 ? err?.error?.message?.en : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({rawError: true})
  public getOne(id: number) {
    this.setLoading(true)

    this.presenter
      .getOne(id)
      .then(res => {
        this.setDetailData(res)
      })
      .catch(err => {
        Vue.notify({
          title: 'Fetch Detail Voucher SKU Limit Failed',
          text:
            err?.status >= 400 && err?.status < 500 ? err?.error?.message?.en : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({rawError: true})
  public create(payload: VoucherConfigSetLimitPayloadInterface): void {
    this.setLoading(true)
    this.setSave(false)

    this.presenter
      .create(
        new VoucherConfigSetLimitRequest(
          payload.rulesName,
          payload.skuGroups,
          payload.target,
          payload.period,
          payload.maxAmount,
          payload.maxQuantity,
          payload.periodAllCustomer,
          payload.maxQuantityAllCustomer,
          payload.maxAmountAllCustomer,
          payload.specificCustomers
        )
      )
      .then(() => {
        this.setSave(true)
      })
      .catch(error => {
        this.setSave(false)
        Vue.notify({
          title: 'Create SKU Limit Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({rawError: true})
  public update(payload: { id: number; data: VoucherConfigSetLimitPayloadInterface }): void {
    this.setLoading(true)
    this.setSave(false)

    this.presenter
      .update(
        payload.id,
        new VoucherConfigSetLimitRequest(
          payload.data.rulesName,
          payload.data.skuGroups,
          payload.data.target,
          payload.data.period,
          payload.data.maxAmount,
          payload.data.maxQuantity,
          payload.data.periodAllCustomer,
          payload.data.maxQuantityAllCustomer,
          payload.data.maxAmountAllCustomer,
          payload.data.specificCustomers,
          payload.data.isActive
        )
      )
      .then(() => {
        this.setSave(true)
      })
      .catch(error => {
        this.setSave(false)
        Vue.notify({
          title: 'Update SKU Limit Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({rawError: true})
  public delete(id: number) {
    this.setLoading(true)

    this.presenter
      .delete(id)
      .then(() => {
        this.setSuccessDelete(true)
      })
      .catch(err => {
        this.setSuccessDelete(false)
        Vue.notify({
          title: 'Delete SKU Limit Failed',
          text:
            err?.status >= 400 && err?.status < 500 ? err?.error?.message?.en : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({rawError: true})
  public uploadBulkySpecificCustomer(payload: { file: File }): void {
    this.setStatusUpload(EnumStatusUpload.PROCESSING)
    this.setErrUploadBulky(null)
    this.setRespUploadBulky(null)

    // set token source for cancel request
    this.cancelToken = Axios.CancelToken.source()

    this.presenter
      .uploadBulky(new UploadRequest(payload.file), this.cancelToken)
      .then(() => {
        this.setStatusUpload(EnumStatusUpload.COMPLETE)
        this.setErrUploadBulky(null)
      })
      .catch(error => {
        if (error.error.message.en.toLowerCase().includes('cancel')) {
          this.setStatusUpload(EnumStatusUpload.START)
        } else {
          this.setRespUploadBulky(null)
          this.setErrUploadBulky(error.error.message.id)
          this.setStatusUpload(EnumStatusUpload.FAILED)
        }
      })
  }

  @Action({ rawError: true })
  public getPreviewUploadBulky(payload: { file: File }): void {
    this.setStatusUpload(EnumStatusUpload.PROCESSING)
    this.setErrUploadBulky(null)
    this.setRespUploadBulky(null)
    this.setUploadFile(payload.file)
    this.setLoading(true)

    // set token source for cancel request
    this.cancelToken = Axios.CancelToken.source()

    this.presenter
      .previewUploadBulky(new UploadRequest(payload.file), this.cancelToken)
      .then(res => {
        this.setStatusUpload(EnumStatusUpload.COMPLETE)
        this.setRespUploadBulky(res)
      })
      .catch(error => {
        this.setRespUploadBulky(null)
        if (error.error.message.en.toLowerCase().includes('cancel')) {
          this.setStatusUpload(EnumStatusUpload.START)
        } else {
          this.setRespUploadBulky(null)
          this.setErrUploadBulky(error.error.message.id)
          this.setStatusUpload(EnumStatusUpload.FAILED)
        }
        Vue.notify({
          title: 'Fetch List Preview Upload Bulky Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({rawError: true})
  public cancelRequest(): void {
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }
  }

  @Mutation
  private setRespUploadBulky(val: UploadBulkyVoucherSetLimitSpecificCustomer | null): void {
    this.responseUploadBulky = val
  }

  @Mutation
  private setListData(data: VoucherSetLimitList) {
    this.paginationData = data.pagination
    this.listData = data.data
  }

  @Mutation
  public setDetailData(data: VoucherSetLimit | null) {
    this.detailData = data
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  public setSave(bool: boolean) {
    this.isSave = bool
  }

  @Mutation
  public setSuccessDelete(bool: boolean) {
    this.successDelete = bool
  }

  @Mutation
  public setStatusUpload(enumStatus: EnumStatusUpload): void {
    this.statusUploadBulky = enumStatus
  }

  @Mutation
  public setForceStart(isForce: boolean): void {
    this.forceStart = isForce
  }

  @Mutation
  private setErrUploadBulky(err: null | string): void {
    this.errUploadBulky = err
  }

  @Mutation
  public setDatRetry(count: number): void {
    this.dataRetry = count
  }

  @Mutation
  setUploadFile(file: File | null): void {
    this.fileUploaded = file
  }
}

export default getModule(VoucherConfigSetLimitController)
