export enum EnumAlertMessage {
  VALID = 'Semua data telah sesuai. Silahkan tekan tombol Kirim Data untuk mengirim data',
  INVALID = '### baris data tidak valid. Silahkan perbaiki data dengan download data excel & upload ulang',
}

export enum EnumInvalidReason {
  COURIER_ID_NOT_FOUND_DESC = "Courier ID tidak ditemukan",
	NON_INTEGER_AMOUNT_DESC = "Jumlah harus berupa angka (positif/negatif)",
	ZERO_AMOUNT_DESC = "Jumlah tidak boleh 0",
}

export enum EnumErrorUploadBulk {
  INVALID_FILE = 'Invalid file'
}

export interface IStyle {
  minWidth: string
  maxWidth: string
}

export interface IDataCell {
  value: number | string | boolean
  customStyle: IStyle
}

export interface IHeaderCell {
  title: number | string
  customStyle: IStyle
}

export interface IOptions {
  label: string
  value: string | number
}

export interface IUploadBulkyResParams {
  page: number
  per_page: number
  status: IOptions
}

export interface IFileData {
  filename: string
  rowInvalid: number
  rowValid: number
  courierInvalid: number
  courierValid: number
  amountInvalid: IFileAmountData
  amountValid: IFileAmountData
}

export interface IFileAmountData {
  addition: number
  reduction: number
}

export enum EnumStatusUpload {
  START = 'Starting',
  UPLOADING = 'Uploading',
  PROCESSING = 'Processing',
  COMPLETE = 'Complete',
  FAILED = 'Failed',
}
