
















































































































































































































import {Vue, Component, Watch} from 'vue-property-decorator'
import IllustrationDownload from '@/app/ui/assets/ill_create_faq.vue'
import Card from '@/app/ui/components/Card/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import {PUBLIC_VOUCHER_PAGINATION, Utils} from '@/app/infrastructures/misc'
import AddCircle from '@/app/ui/assets/add_circle.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import {DataObject, HeaderObject} from '@/app/ui/components/DataTableV2/type'
import EditIcon from '@/app/ui/assets/edit_icon_alt.vue'
import TrashIcon from '@/app/ui/assets/trash_icon_alt2.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import ModalUploadBulky from '@/app/ui/components/Modal/Modalupload.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import IconInfo from '@/app/ui/assets/icon_warning_circle.vue'
import CloseLine from '@/app/ui/assets/close_icon_line.vue'
import controller, {VoucherConfigSetLimitInterface} from '@/app/ui/controllers/VoucherConfigSetLimitController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'

type TableItem = Array<Array<DataObject>>

@Component({
  components: {
    LoadingOverlay,
    CloseLine,
    IconInfo,
    Toggle,
    Modal,
    ModalAction,
    ModalUploadBulky,
    DataTableV2,
    PaginationNav,
    AddCircle,
    DropdownSelect,
    TextInput,
    Card,
    IllustrationDownload,
    Button,
    EditIcon,
    TrashIcon,
    Badge,
  }
})
export default class SetLimitListPage extends Vue {
  controller = controller
  filterOptions = [
    {label: 'Show All', value: 'SHOW_ALL'},
    {label: 'Active', value: 'ACTIVE'},
    {label: 'Pending', value: 'PENDING'},
    {label: 'Cancelled', value: 'CANCELLED'},
    {label: 'Expired', value: 'EXPIRED'},
  ]
  sortOptions = [
    {label: 'Latest', value: 'DESC'},
    {label: 'Oldest', value: 'ASC'},
  ]
  parameters = {
    page: 1,
    perPage: PUBLIC_VOUCHER_PAGINATION,
    keyword: null,
    sortBy: this.sortOptions[0],
    filterBy: this.filterOptions[0],
  }

  headers: HeaderObject[] = [
    {
      title: 'Rules Name',
      width: `180px`,
    },
    {
      title: 'Total SKU',
      width: `130px`,
    },
    {
      title: 'Type Customer',
      width: '240px',
    },
    {
      title: 'Period'
    },
    {
      title: 'Amount',
      width: `165px`
    },
    {
      title: 'QYT'
    },
    {
      title: 'Status',
      width: '170px',
    },
    {
      title: 'Action',
      width: '170px',
    },
  ]

  headersModal: HeaderObject[] = []
  dataItemsModal = []

  showUploadModal = false
  showModalConfirm = false
  showModalSuccess = false
  titleInfo = 'Type Customer'
  showModalInfo = false
  modalActionData = {
    title: '',
    description: '',
    textPrimary: '',
    textSecondary: '',
  }
  modalSuccess = {
    title: 'Success',
    desc: 'Data has been updated successfully',
  }
  setID = 0
  setIndex = -1

  created(): void {
    this.fetchData()
  }

  get dataItems(): TableItem {
    const finalData: TableItem = []
    this.controller.listData.forEach((val, index) => {
      const item = []

      let period = ''
      let typeCustomer = ''
      let qty = ''
      let amount = ''
      const specificCustomerEmpty = val.specificCustomers && val.specificCustomers.length === 0
      if (val.periodAllCustomer && val.periodAllCustomer !== '' && specificCustomerEmpty && !val.target) {
        typeCustomer = 'General'
        period = val.periodAllCustomer
        qty = String(val.maxQtyAllCustomer || '')
        amount = Utils.toRupiah(val.maxAmountAllCustomer || 0)
      }

      if (val.target && val.target === 'PER_CUSTOMER') {
        typeCustomer = '1 used / customer'
        period = val.period || ''
        qty = String(val.maxQty || '')
        amount = Utils.toRupiah(val.maxAmount || 0)
      }

      if (val.target && val.target !== 'PER_CUSTOMER' && val.specificCustomers && val.specificCustomers.length > 0) {
        typeCustomer = 'Specific Customer'
        period = val.period || ''
        qty = String(val.maxQty || '')
        amount = Utils.toRupiah(val.maxAmount || 0)
      }

      if (val.periodAllCustomer && val.periodAllCustomer !== '' && val.target && val.target !== 'PER_CUSTOMER') {
        item.push([
          {
            value: val.ruleName,
            width: `180px`,
          },
          {
            id: index,
            value: val.skuGroups.length,
            width: `130px`,
          },
          {
            id: index,
            width: '240px',
            value: 'General Customer'
          },
          {
            value: val.periodAllCustomer
          },
          {
            value: Utils.toRupiah(val.maxAmountAllCustomer || 0),
            width: `165px`
          },
          {
            value: val.maxQtyAllCustomer || ''
          },
          {
            id: index,
            value: val.isActive,
            width: '170px',
          },
          {
            value: val.id,
            width: '170px',
          },
        ])
        item.push([
          {
            value: '',
            width: `180px`,
          },
          {
            id: index,
            value: val.skuGroups.length,
            width: `130px`,
          },
          {
            id: index,
            width: '240px',
            value: 'Specific Customer'
          },
          {
            value: val.period
          },
          {
            value: Utils.toRupiah(val.maxAmount || 0),
            width: `165px`
          },
          {
            value: val.maxQty || ''
          },
          {
            id: index,
            value: val.isActive,
            width: '170px',
          },
          {
            value: val.id,
            width: '170px',
          },
        ])
      } else if (val.periodAllCustomer && val.periodAllCustomer !== '' && val.target && val.target === 'PER_CUSTOMER') {
        item.push([
          {
            value: val.ruleName,
            width: `180px`,
          },
          {
            id: index,
            value: val.skuGroups.length,
            width: `130px`,
          },
          {
            id: index,
            width: '240px',
            value: 'General'
          },
          {
            value: val.periodAllCustomer
          },
          {
            value: Utils.toRupiah(val.maxAmountAllCustomer || 0),
            width: `165px`
          },
          {
            value: val.maxQtyAllCustomer || ''
          },
          {
            id: index,
            value: val.isActive,
            width: '170px',
          },
          {
            value: val.id,
            width: '170px',
          },
        ])
        item.push([
          {
            value: '',
            width: `180px`,
          },
          {
            id: index,
            value: val.skuGroups.length,
            width: `130px`,
          },
          {
            id: index,
            width: '240px',
            value: '1 used / customer'
          },
          {
            value: val.period
          },
          {
            value: Utils.toRupiah(val.maxAmount || 0),
            width: `165px`
          },
          {
            value: val.maxQty || ''
          },
          {
            id: index,
            value: val.isActive,
            width: '170px',
          },
          {
            value: val.id,
            width: '170px',
          },
        ])
      } else {
        item.push(
          {
            value: val.ruleName,
            width: `180px`,
          },
          {
            id: index,
            value: val.skuGroups.length,
            width: `130px`,
          },
          {
            id: index,
            width: '240px',
            value: typeCustomer
          },
          {
            value: period
          },
          {
            value: amount,
            width: `165px`
          },
          {
            value: qty
          },
          {
            id: index,
            value: val.isActive,
            width: '170px',
          },
          {
            value: val.id,
            width: '170px',
          },
        )
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      finalData.push(item)
    })

    return finalData
  }

  private openModal(): void {
    this.showUploadModal = true
  }

  private openModalInfo(title: string, index: number): void {
    const data = this.controller.listData[index]
    if (title.startsWith('Daftar')) {
      this.headersModal = [
        {
          title: 'Daftar SKU',
        },
        {
          title: 'Name',
        },
      ]

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.dataItemsModal = data.skuGroups.map((val) => {
        return [
          {
            value: val.sku,
          },
          {
            value: val.name,
          },
        ]
      })
    } else {
      this.headersModal = [
        {
          title: 'Customer ID',
        },
      ]
      if (data.specificCustomers && data.specificCustomers.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.dataItemsModal = data.specificCustomers.map((val) => {
          return [
            {
              value: val,
            },
          ]
        })
      }
    }
    this.titleInfo = title
    this.showModalInfo = true
  }

  private closeModalInfo(): void {
    this.showModalInfo = false
    this.dataItemsModal = []
    this.titleInfo = ''
    this.headersModal = []
  }

  private fetchData(isReset = false): void {
    if (isReset) {
      this.parameters.page = 1
    }
    const params: VoucherConfigSetLimitInterface = {
      sort: this.parameters.sortBy.value,
    }

    if (this.parameters.keyword) {
      params.keyword = String(this.parameters.keyword)
    }
    controller.getAll(params)
  }

  actionShowConfirm(id: number): void {
    this.setID = id
    this.showModalConfirm = true
    this.modalActionData = {
      title: `Are you sure you want to delete?`,
      description: 'Data can’t be restore',
      textPrimary: 'Delete',
      textSecondary: 'Cancel',
    }
  }

  onChangeStatus(e: Event, index: number): void {
    e.preventDefault()
    this.setID = controller.listData[index].id
    this.setIndex = index
    this.showModalConfirm = true
    this.modalActionData = {
      title: `Are you sure you want to change status?`,
      description: 'Data can’t be restore',
      textPrimary: controller.listData[index].isActive ? 'Deactivate' : 'Activate',
      textSecondary: 'Cancel',
    }
  }

  doActionModal(): void {
    this.showModalConfirm = false
    if (this.modalActionData.textPrimary.endsWith('ate') && this.setID !== -1) {
      const data = controller.listData[this.setIndex]
      controller.update({
        id: this.setID,
        data: {
          maxAmount: data.maxAmount ? data.maxAmount : undefined,
          maxQuantity: data.maxQty ? data.maxQty : undefined,
          period: data.period ? data.period : undefined,
          maxQuantityAllCustomer: data.maxQtyAllCustomer ? data.maxQtyAllCustomer : undefined,
          maxAmountAllCustomer: data.maxAmountAllCustomer ? data.maxAmountAllCustomer : undefined,
          periodAllCustomer: data.periodAllCustomer ? data.periodAllCustomer : undefined,
          target: data.target ? data.target : undefined,
          rulesName: data.ruleName,
          isActive: !data.isActive,
          skuGroups: data.skuGroups.map((val) => {
            return val.id
          }),
          specificCustomers: data.specificCustomers ? data.specificCustomers.map(
            (val) => {
              return +val
            }
          ) : undefined,
        }
      })
    } else {
      controller.delete(this.setID)
    }
  }

  onCloseSuccessModal(): void {
    this.fetchData(false)
    this.showModalSuccess = false
  }

  @Watch('controller.successDelete')
  onWatchSuccessDelete(val: boolean): void {
    if (val) {
      this.modalSuccess = {
        title: 'Deleted',
        desc: 'The SKU Limit is successfully deleted',
      }
      this.showModalSuccess = true
      controller.setSuccessDelete(false)
      this.setID = 0
      this.setIndex = -1
    }
  }

  @Watch('controller.isSave')
  onIsSaveChange(isSave: boolean): void {
    if (isSave) {
      this.modalSuccess = {
        title: 'Status Updated',
        desc: 'The SKU Limit status is successfully updated',
      }
      this.showModalSuccess = true
      this.setID = 0
      this.setIndex = -1
      controller.setSave(false)
    }
  }
}
