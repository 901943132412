import {VoucherConfigSetLimitRequestInterface} from "@/data/payload/contracts/VoucherConfigSetLimitRequest"

export interface VoucherConfigSetLimitPayloadInterface {
  rulesName: string
  skuGroups: number[]
  target?: string
  period?: string
  maxAmount?: number
  maxQuantity?: number
  periodAllCustomer?: string
  maxQuantityAllCustomer?: number
  maxAmountAllCustomer?: number
  specificCustomers?: number[]
  isActive?: boolean
}

export class VoucherConfigSetLimitRequest implements VoucherConfigSetLimitRequestInterface {
  private readonly rulesName: string
  private readonly skuGroups: number[]
  private readonly target?: string
  private readonly period?: string
  private readonly maxAmount?: number
  private readonly maxQuantity?: number
  private readonly periodAllCustomer?: string
  private readonly maxQuantityAllCustomer?: number
  private readonly maxAmountAllCustomer?: number
  private readonly specificCustomers?: number[]
  private readonly isActive?: boolean

  constructor(
    rulesName: string,
    skuGroups: number[],
    target?: string,
    period?: string,
    maxAmount?: number,
    maxQuantity?: number,
    periodAllCustomer?: string,
    maxQuantityAllCustomer?: number,
    maxAmountAllCustomer?: number,
    specificCustomers?: number[],
    isActive?: boolean
  ) {
    this.rulesName = rulesName
    this.skuGroups = skuGroups
    this.target = target
    this.period = period
    this.maxAmount = maxAmount
    this.maxQuantity = maxQuantity
    this.periodAllCustomer = periodAllCustomer
    this.maxQuantityAllCustomer = maxQuantityAllCustomer
    this.maxAmountAllCustomer = maxAmountAllCustomer
    this.specificCustomers = specificCustomers
    this.isActive = isActive
  }

  public toPayload(): string {
    return JSON.stringify({
      rule_name: this.rulesName,
      sku_groups: this.skuGroups,
      target: this.target,
      period: this.period,
      max_amount: this.maxAmount,
      max_qty: this.maxQuantity,
      period_all_customer: this.periodAllCustomer,
      max_qty_all_customer: this.maxQuantityAllCustomer,
      max_amount_all_customer: this.maxAmountAllCustomer,
      specific_customers: this.specificCustomers?.map(v => Number(v)),
      is_active: this.isActive
    });
  }
}
